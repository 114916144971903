.frame.disabled .square {
  cursor: default;
}

.row {
  display: flex;
}

.square {
  width: 67px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 2px;
  flex: 1;
  text-overflow: clip;
  /* color: #2a2c38; */
  color: black;
  font-size: 12.5px;
  word-break: keep-all;
  cursor: pointer;
  font-size: 13px;
}

.square.long {
  font-size: 12px;
}

.square.activated {
  background-color: #faf2b3;
  color: #2a2c38;
}

.square.unclickable {
  background-color: #fa747b;
  color: white;
  font-size: 26px;
  font-weight: bold;
  cursor: help;
}

/* STYLED COMPONENTS */
/* background-color 3가지 경우의 수 */
/* background-color: ${unclickable ? "#fa747b" : "white"};
color: ${unclickable ? "white" : "#2a2c38"}
font-size: ${unclickable ? "26px" : "12.5px"}
font-weight: ${unclickable ? "bold" : "regular"}
cursor: ${unclickable ? "help" : "pointer"}; */
